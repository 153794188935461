<template>
    <div>
        <div class="my_info_top">
            <div class="left_photo">
                <img v-if="img" :src="img" alt="">
                <img v-else src="@/assets/defalut_img.png" alt="">
            </div>
            <div class="right_name">
                <div class="name">{{dispname || '游客'}}</div>
                <div class="info">{{info}}</div>
            </div>
        </div>

        <div class="config_list">
            <div class="one" @click="changeClinic" v-if="size > 1">
                <div class="name">切换诊所</div>
                <div class="arrow">
                    <i class="icon iconfont iconyou"></i>
                </div>
            </div>

            <!--<div class="one" @click="changeClinic">
                <div class="name">切换诊所</div>
                <div class="arrow">
                    <i class="icon iconfont iconyou"></i>
                </div>
            </div>-->
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    export default {
        computed: {
            ...mapGetters([
                'username',
                'dispname',
                'clinicid',
                'clinicname',
                'img',
                "cliniclist"
            ]),
        },
        data() {
            return {
                info:'未绑定诊所',
                size:0
            }
        },
        mounted () {
            this.$store.dispatch('changeNav', '1')
            console.log(this.cliniclist, '----cliniclist')
            if(this.cliniclist != null) {
                this.size = this.cliniclist.length
            }
            console.log(this.size, '----size')
            this.$store.dispatch('hideOrShowNav', true)
            if(this.clinicname && this.clinicname != '' && this.clinicname != undefined && this.clinicname != 'undefined') {
                this.info = "已登录【" + this.clinicname + "】"
            }
        },
        methods: {
            changeClinic() {
                this.$router.push({
                    path: '/changeClinic'
                })
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import '@/styles/flex.scss';
    .my_info_top{
        width: 100%;
        height: 150px;
        padding: 0 24px;
        background: #ffffff;
        @include lc-row-start();
        .right_name{
            width: 200px;
            .name {
                font-weight: 600;
                font-size: 20px;
                margin-bottom: 5px;
            }
            .info {
                color: #EAB03C;
                //超出多少行显示省略号
                -webkit-line-clamp: 2;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                overflow: hidden;
                word-break: break-all;
            }

        }
        .left_photo{
            width: 88px;
            height: 88px;
            border-radius: 50%;
            border: 2px solid #fff;
            margin-right: 20px;
            img{
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }
    }
    .config_list{
        padding: 0 16px;
        .one{
            width: 100%;
            @include lc-row-between();
            height: 46px;
            border-radius: 99px;
            background-color: #f7f8fa;
            padding: 0px 20px;
            transition: background 0.3s;
            color: #323233;
            font-weight: 600;
            margin: 0 0 12px;
            .name{
                font-size: 16px;
            }
            .arrow{
                font-size: 20px;
            }
        }
    }
</style>